import React from "react";
import "@fontsource/lato";

const colorBox = {
  width: "100%",
  height: "50vh",
  borderRadius: "20px",
};

export default function ColorTemplate(data) {
  const {
    hex,
    name,
    displayedName,
    isAvailable,
    openSeaUrl,
  } = data.pageContext.colorData;

  const availabilityNode = isAvailable === true ? (
    <>
      {name} is available on <a href={openSeaUrl}>OpenSea</a>
    </>
  ) : (
    <>As of now, {name} is not yet released. Stay tuned.</>
  );
  return (
    <div id="wrapper">
      <main id="main">
        <div className="inner">
          <title>A Color Needs A Name. - {name}</title>
          <h1>
            <a href="/">A Color Needs A Name.</a>
          </h1>
          <h2>{displayedName}</h2>
          <section>
            <div style={{ ...colorBox, background: `#${hex}` }} />
          </section>
          <section>
            <h3>How to get {displayedName}?</h3>

            {availabilityNode}
          </section>
          </div>
      </main>
    </div>
  );
}
